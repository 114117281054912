<template>
  <sign-page
    title-text="考核项目"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParms"
    :dealSearchParm="dealSearchParm"
    :form-parms-update="formParms"
    :dealFormData="dealFormData"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  appraisalItemRequest as request
} from '../../../api'

export default {
  props: {
    appraisalId: {
      type: [String, Number]
    },
    appraisalType: {
      type: [String, Number]
    }
  },
  computed: {
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '序号',
          key: 'orderNum',
          check: {
            required: true,
            rules: {
              type: 'integer'
            }
          }
        },
        {
          type: 'input',
          label: '标题',
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '内容',
          key: 'content'
        },
        {
          type: 'select',
          label: '类型',
          key: 'type',
          selectDatas: this.appraisalType === 'scoring' || this.appraisalType === 'question' ? [{
            key: 'score',
            label: '打分'
          }] : [{
            key: 'vote',
            label: '投票'
          }],
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '打分类型',
          key: 'scoreType',
          selectDatas: [{
            key: 'select',
            label: '选择'
          },
          {
            key: 'input',
            label: '输入'
          }],
          show (data) {
            return data.type === 'score'
          },
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '可选项',
          key: 'optionalListStr',
          show (data) {
            return data.type === 'score' && data.scoreType === 'select'
          }
        },
        {
          type: 'input',
          label: '最大分值',
          key: 'maxValue',
          check: {
            rules: {
              type: 'number'
            }
          },
          show (data) {
            return data.type === 'score' && data.scoreType === 'input'
          }
        },
        {
          type: 'input',
          label: '最小分值',
          key: 'minValue',
          check: {
            rules: {
              type: 'number'
            }
          },
          show (data) {
            return data.type === 'score' && data.scoreType === 'input'
          }
        },
        {
          type: 'input',
          label: '小数点位数',
          key: 'decimalNum',
          check: {
            rules: {
              type: 'integer'
            }
          },
          show (data) {
            return data.type === 'score' && data.scoreType === 'input'
          }
        },
        {
          type: 'input',
          label: '每人最多投票数',
          key: 'maxNum',
          check: {
            rules: {
              type: 'integer'
            }
          },
          show (data) {
            return data.type === 'vote'
          }
        },
        {
          type: 'input',
          label: '每人最少投票数',
          key: 'minNum',
          check: {
            rules: {
              type: 'integer'
            }
          },
          show (data) {
            return data.type === 'vote'
          }
        },
        {
          type: 'input',
          label: '单人最多投票数',
          key: 'itemMaxNum',
          check: {
            rules: {
              type: 'integer'
            }
          },
          show (data) {
            return data.type === 'vote'
          }
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '序号',
          field: 'orderNum'
        },
        {
          title: '题目',
          field: 'title'
        }]
        return data
      }
    }
  },
  methods: {
    dealFormData (parm) {
      parm.appraisalId = this.appraisalId
      let rule = {}
      if (parm.type === 'score') {
        rule = {
          type: parm.scoreType
        }
        if (rule.type === 'input') {
          rule.maxValue = parm.maxValue
          rule.minValue = parm.minValue
          rule.decimalNum = parm.decimalNum
        } else {
          rule.optionalList = parm.optionalListStr ? parm.optionalListStr.split(',').map((v, index) => {
            return {
              label: v.split('_')[0],
              value: Number(v.split('_')[1]),
              orderNum: index + 1
            }
          }) : []
        }
      } else {
        rule = {
          maxNum: parm.maxNum,
          minNum: parm.minNum,
          itemMaxNum: parm.itemMaxNum
        }
      }
      parm.rule = JSON.stringify(rule)
      return parm
    },
    dealSearchParm (parm) {
      parm = parm || {}
      parm.appraisalId = this.appraisalId
      return parm
    },
    async loadData () {
      let datas = await request.get({
        appraisalId: this.appraisalId
      })
      datas.forEach(v => {
        let rule = v.rule ? JSON.parse(v.rule) : {}
        if (v.type === 'score') {
          v.scoreType = rule.type
          v.optionalListStr = (rule.optionalList || []).map(v => v.label + '_' + v.value).join(',')
          v.maxValue = rule.maxValue
          v.minValue = rule.minValue
          v.decimalNum = rule.decimalNum
        } else {
          v.maxNum = rule.maxNum
          v.minNum = rule.minNum
          v.itemMaxNum = rule.itemMaxNum
        }
      })
      datas.sort((a, b) => {
        return a.orderNum - b.orderNum
      })
      return datas
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
