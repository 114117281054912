<template>
  <div class="ready-img">
    <fm-btn class="border-btn" @click="chooseImg('screen')">大屏封面</fm-btn>
    <fm-btn class="border-btn" @click="chooseImg('my')">我的考核封面</fm-btn>
    <fm-modal
      :mask-closable="false"
      :value="openDialog"
      width="50%"
      :title="type === 'screen' ? '大屏封面' : '我的考核封面'"
      v-if="openDialog"
      theme="mh-withe"
      @cancel="openDialog = false;">
      <template>
        <img :src="showImg" v-if="showImg" style="width: 100%" v-loading="loading" @click="$refs.upload.click()"/>
        <div class="sc-btn" v-loading="loading">
          <fm-btn @click="$refs.upload.click()" style="margin: 1rem;">选择图片</fm-btn>
        </div>
      </template>
      <input type="file" style="display: none;" @change="fileChange" ref="upload">
    </fm-modal>
  </div>
</template>

<script>
import { fileRequest } from '@/api'

import {
  appraisalRequest
} from '../../../api'

export default {
  props: {
    appraisalData: {
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      openDialog: false,
      type: '',
      screenImg: null,
      myImg: null
    }
  },
  watch: {
    appraisalData: {
      handler () {
        this.loadMy()
        this.loadScreen()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    showImg () {
      return this.type === 'screen' ? this.screenImg : this.myImg
    }
  },
  methods: {
    async fileChange () {
      let files = this.$refs.upload.files
      if (files.length) {
        let file = files[0]
        if (['.png', '.jpg', '.jpeg', '.gif'].findIndex(v => file.name && new RegExp(v, 'i').test(file.name)) === -1) {
          this.$notice.info('请选择图片')
          return
        } else {
          let parm = new FormData()
          parm.append('file', file)
          let d = await fileRequest.upload(parm)
          let p = {}
          if (this.type === 'my') {
            p.myImgId = d.id
          } else {
            p.screenImgId = d.id
          }
          await appraisalRequest.updateFile(this.appraisalData.id, p)
          this.$emit('fileChange')
          this.$notice.success('上传成功')
        }
      }
      // this.upload()
    },
    async loadScreen () {
      this.loading = true
      try {
        if (this.appraisalData && this.appraisalData.screenImgId) {
          let fileData = await fileRequest.download({
            id: this.appraisalData.screenImgId
          })
          if (fileData) {
            const src = await new Promise(resolve => {
              let reader = new FileReader()
              reader.readAsDataURL(fileData)
              reader.onload = () => resolve(reader.result)
            })
            this.screenImg = src
          } else {
            this.screenImg = null
          }
        } else {
          this.screenImg = null
        }
      } catch (e) {
        this.loading = false
        this.screenImg = null
      }
      this.loading = false
    },
    async loadMy () {
      this.loading = true
      try {
        if (this.appraisalData && this.appraisalData.myImgId) {
          let fileData = await fileRequest.download({
            id: this.appraisalData.myImgId
          })
          if (fileData) {
            const src = await new Promise(resolve => {
              let reader = new FileReader()
              reader.readAsDataURL(fileData)
              reader.onload = () => resolve(reader.result)
            })
            this.myImg = src
          } else {
            this.myImg = null
          }
        } else {
          this.myImg = null
        }
      } catch (e) {
        this.loading = false
        this.myImg = null
      }
      this.loading = false
    },
    chooseImg (type) {
      this.type = type
      this.openDialog = true
    }
  }
}
</script>

<style lang="less" scoped>
.sc-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}
.ready-img {
  padding-right: 10px;
}
.border-btn {
  background-color: #fff !important;
  color: #00CCC4 !important;
  border: 1px solid #00CCC4 !important;
  margin-right: 5px;
  padding: 0 22px;
}
</style>