import { render, staticRenderFns } from "./Imgs.vue?vue&type=template&id=46d549e4&scoped=true&"
import script from "./Imgs.vue?vue&type=script&lang=js&"
export * from "./Imgs.vue?vue&type=script&lang=js&"
import style0 from "./Imgs.vue?vue&type=style&index=0&id=46d549e4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d549e4",
  null
  
)

export default component.exports